import $ from 'jquery';
import 'tilt.js';

if (module.hot) {
  module.hot.accept();
}

$(window.document).ready(() => { //eslint-disable-line
  $('.js-tilt').tilt();
});
